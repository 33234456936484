import './badge.css';

import React from 'react'

export default function Badge() {
    return (
        <div className='about'>
            <div className='a-image'>
                <img src='./images/1.PNG'></img>
            </div>
            <div className='a-detail'>
                <h2>FOR OVER <span style={{fontSize:'3rem'}}>20</span> YEARS</h2>
            </div>
        </div>
    )
}
