import './contact.css';

import React from 'react'
import { BiSolidPhoneCall } from 'react-icons/bi';
import NavBar from '../NavBar/NavBar';

export default function Contact() {
  return (
    <>
      <NavBar />
      <div className='c-section'>
        <div className='c-detail'>
          <h2>Contact Information</h2>
          <span>Vishwa Electricals</span> <br />
          <span>250/A GandhiNagar School Road </span> <br />
          <span>Gandhi Nagar</span> <br />
          <span>Sullia</span>
          <h2>Business Hours</h2>
          <span>Monday-Saturday: 9:00 AM to 7:00 PM</span>
          <br />
          <br />
          <h2>Service Area</h2>
          <span>Sullia-Puttur and Surrounding Area</span>
        </div>
        <div className="phone-number">
          <h3><BiSolidPhoneCall /> +91-9448869856</h3>
        </div>
      </div>
      <div className='c-map'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3894.4118850738614!2d75.3974439!3d12.5550702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba4f384a92444a5%3A0x347032f9e2c19efa!2sVishwa%20Electrical%20works%20sullia%20250%2FA%20School%20road%20Gandhinagar%20Sullia%20574239%20D.k%20Karnataka!5e0!3m2!1sen!2sca!4v1696264097870!5m2!1sen!2sca" width="100%" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </>
  )
}
