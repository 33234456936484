import './Footer.css';

import React from 'react'
import { AiFillInstagram } from 'react-icons/ai';
import { BsGithub } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';
import { AiTwotoneMail } from 'react-icons/ai';
import { HiOutlinePhoneMissedCall } from 'react-icons/hi'

export default function Footer() {
    return (
        <>
            <div className='footer'>
                <div className='f-content'>
                    <div className='f-detail'>
                        <h2>Contact Information</h2>
                        <span>Vishwa Electrical Works</span> <br />
                        <span>250/A GandhiNagar School Road</span> <br />
                        <span>Gandhi Nagar</span> <br />
                        <span>Sullia</span>
                        <br />
                        <h2>Contact us at</h2>
                        <span> <HiOutlinePhoneMissedCall />+91- 9448869856</span>
                    </div>
                    <div className='f-hours'>
                        <h2>Business Hours</h2>
                        <span>Monday-Saturday: 9:00 AM to 7:00 PM</span>
                        <br />
                        <br />
                        <h2>Service Area</h2>
                        <span>Sullia-Puttur and Surrounding Area</span>
                    </div>
                </div>

                <div className='f-info'>
                    {/* <span><AiTwotoneMail />example@gmail.com</span> */}
                    <br />
                </div>
            </div>
        </>
    )
}
