import './BackImage.css';
import React from 'react'

export default function BackImage() {
    return (
        <>
            <div className='banner-img' style={{ backgroundImage: `url(./images/electrician.jfif)` }}>
            </div>
            <div className='banner-text'>
                <h1>Vishwa Electricals</h1>
                <p>Welcome to Vishwa Electricals, your one-stop destination for all your electrical needs. We are proud to be your trusted partner in providing high-quality electrical products and expert services that power your world.
                </p>
                <a href="ContactNav">Contact Us</a>
            </div>
        </>
    )
}
