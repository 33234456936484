import { useState } from 'react';
import React from 'react'
import './NavBar.css';
import { GiHamburgerMenu } from 'react-icons/gi';
import {AiTwotoneTool} from 'react-icons/ai';

export default function NavBar() {
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <nav>
            <div className='title'> <AiTwotoneTool size={40}/> Vishwa <br/>Electricals</div>
            <div className='menu' onClick={() => {
                setMenuOpen(!menuOpen)
            }}>
                <GiHamburgerMenu size={30} />
            </div>
            <ul style={{ listStyleType: 'none' }} className={menuOpen ? "open" : "NoStyle"}>
                <a href="/"><li>Home</li></a>
                <a href="About"><li>About Us</li></a>
                <a href="ContactNav"><li>Contact</li></a>
            </ul>
        </nav>
    )
}
