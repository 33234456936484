import Footer from '../Footer/Footer';
import NavBar from '../NavBar/NavBar';
import './about.css';

import React from 'react'

export default function About() {
    return (
        <>
            <NavBar />
            <div className='a-heading'>
                <h1>Your Trusted Electrical solution provider</h1>
            </div>
            <div className='a-para'>
                <p>Welcome to Vishwa Electricals, your one-stop destination for all your electrical needs. We are proud to be your trusted partner in providing high-quality electrical products and expert services that power your world.</p>
                <p>At Vishwa Electricals, our commitment is simple but unwavering: to deliver top-notch electrical solutions that ensure safety, efficiency, and reliability. We understand the pivotal role that electricity plays in our daily lives, both at home and in the workplace. That's why we strive to offer the best products and services to meet your electrical requirements.</p>
            </div>



            <div className='why'>
                <div className='why-image'>
                    <div className='s-image'><img src='./images/circuits.jpg' height={400} width={500} /></div>
                    <div className='f-image'><img src='./images/electrician.jpg' height={400} width={500} /></div>
                </div>
                <div className='why-para'>
                    <h1>Why Choose <br /> Vishwa Electricals</h1>
                    <ul>
                        <li>Quality Assurance</li> <p>We source our products from reputable manufacturers known for their quality and reliability, ensuring that you get the best value for your money.</p>
                        <li>Safety First</li> <p>Your safety is our priority. We provide guidance on safe installation practices and offer products that meet or exceed industry safety standards.</p>
                        <li>Expert Advice</li> <p>Our knowledgeable staff is always ready to answer your questions, provide technical support, and offer solutions tailored to your specific needs.</p>
                        <li>Service Excellence</li> <p> We stand behind our products and services. If you encounter any issues, our service team is here to help with repairs, maintenance, and troubleshooting.</p>
                    </ul>
                </div>
            </div>


            <Footer />
        </>
    )
}
