import './App.css';
import Main from './Main/Main';
import About from './About/About';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from './Contact/Contact';


function App() {
  return (
    <>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Main/>}/>
        <Route path='About' element={<About/>}/>
        <Route path='ContactNav' element={<Contact/>}/>
      </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
