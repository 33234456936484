import React from 'react'
import NavBar from '../NavBar/NavBar';
import BackImage from '../BackImage/BackImage';
import Footer from '../Footer/Footer';
import Services from '../Services/Services';
import Tool from '../Tool/Tool';
import Badge from '../Badge/Badge';

export default function () {
  return (
    <>
    <NavBar/>
    <BackImage/>
    <Badge/>
    <Tool/>
    <Services/>
    <Footer/>
    </>
  )
}

