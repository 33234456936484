import React from 'react'
import './tool.css';
import { motion } from "framer-motion";
import { FaReact } from 'react-icons/fa';
import { TbBrandNextjs } from 'react-icons/tb';
import { SiMysql } from 'react-icons/si';
import { BsBootstrapFill } from 'react-icons/bs';

export default function Tool() {
  return (
    <div className='w-right'>
      
    </div>
  )
}
