import React from 'react'
import './Services.css';

export default function ServiceProp(props) {
  return (
    <div className='sub-list'>
      <h2>{props.title}</h2>
      <p>{props.description}</p>
    </div>
  )
}
