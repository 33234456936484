import React from 'react'
import './Services.css';
import ServiceProp from './ServiceProp';

export default function Services() {
    return (
        <div className='services'>
            <div className='container'>
                <h1 className='sub-title'>My Services</h1>
                <div className='services-list'>
                    <ServiceProp title="Electrical Contractor" description="Commercial and industrial electrical contractor offering full range of services"/>

                    <ServiceProp title="Electrical service" description="For every electrical services like current connection for house,businesses, fixing electrical devices." />

                    <ServiceProp title="Surveillance Camera" description="For top notch security for your place contact us for setting up the Surveillance cameras" />
                    
                    <ServiceProp title="Generator sales/Installation" description="Vishwa electricals Generatoe service provides  emergency backup for  Commercial & Residential electricity" />
                    
                    <ServiceProp title="Inverters" description="We provide quick installation of inverter Packed with great features  at your residence or commercial place." />
                
                    <ServiceProp title=" Electrical Motor sales/Services" description="Vishwa electricals is proud to offer motor solution for your farms and  drinking water needs.  These high-content motors add features like cooling and pumping  and are enclosed in lightweight housings." />
                    
                    <ServiceProp title="High speed cutting machine service" description="If you are looking for a high speed machine cutting, Granite and wood cutting service then Contact Us soon!" className="last-list"/>
                </div>
            </div>

        </div>
    )
}
